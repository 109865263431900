* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--color-bg); */
  background-color: #2c2c2c;
  color: var(--color-white-high);
  line-height: 1.5;
  /* background-image: url("../src/assets/bg\ \(3\).jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  background-image: url("../src/assets/bg\ \(5\).png");
  background-blend-mode: color-dodge;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-white-high: rgba(255, 255, 255, 0.87);
  --color-white-med: rgba(255, 255, 255, 0.6);
  --color-white-disabled: rgba(255, 255, 255, 0.39);
  --color-white-google: #bdc1c6;
  --color-bg-yt: #181818;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition-main: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition-main);
}

a:hover {
  color: var(--color-white-google);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  border: 1px solid var(--color-primary);
  transition: var(--transition-main);
}

.btn:hover {
  background-color: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ===================== MEDIUM DEVICES - Media Queries ===================== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-top: 6rem;
  }
}

/* ===================== SMALL DEVICES - Media Queries ===================== */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section > h2 {
    margin-bottom: 2rem;
  }
}
